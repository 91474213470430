<template>
  <div class="landing">
    <!-- <HeaderLanding />
    <BannerTop />
    <Login />
    <BannerMiddle />
    <Login />
    <Partners />
    <Footer /> -->
  </div>
</template>

<script>
// import HeaderLanding from "@/components/HeaderLanding.vue"
// import BannerTop from "@/components/BannerTop.vue"
// import Login from "@/components/Login.vue"
// import BannerMiddle from "@/components/BannerMiddle.vue"
// import Partners from "@/components/Partners.vue"
// import Footer from "@/components/Footer.vue"

export default {
  name: 'Landing',
  components: {
    // HeaderLanding,
    // BannerTop,
    // Login,
    // BannerMiddle,
    // Partners,
    // Footer
  }
}
</script>

<style lang="scss" scoped>
  .landing {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
</style>
