{
  "meta": {
    "Home": {
      "title": "The Sandbox - スヌープ・ドッグ（Snoop Dogg）",
      "description": ""
    },
    "favicon": "/favicon.ico"
  },
  "bannerTop": {
    "title": "Snoop Dogg",
    "subtitle1": "メタバースへ",
    "paragraph1": "Snoop DoggはThe Sandbox内に邸宅を建設中です。招待してもいいですか？",
    "paragraph2": "ボール遊びをしたり、映画を見たり、プールサイドでくつろいだり。Snoop Doggの豪邸を訪れて、本人に近づいてみましょう。彼の自宅周辺が公開されました。",
    "paragraph3": ""
  },
  "login": {
    "button": "ログインまたはアカウント作成",
    "descriptionRegular1": "登録すると、ゲームが配信時に、Snoop Dogg限定サングラスを無料でゲットできます。"
  },
  "bannerMiddle": {
    "title1": "遊ぶ、作る",
    "title2": "所有し、統治する",
    "subtitle1": "プレイヤーが作る仮想空間",
    "paragraph1": "シングルプレイヤーとマルチプレイヤーの両方の体験をプレイすることができます。Voxedit 3Dでアセットを作成し、アニメーション化しませんか？Game Makerを使って、The Sandbox Metaverseでユニークなゲーム体験を作りましょう。マーケットプレイスでアセットを購入・販売して、ゲーム体験を向上させましょう。",
    "paragraph6": "The Sandboxに限界はありません！",
    "button1": "利用可能なプロパティを見る",
    "title3": "探検・発見",
    "subtitle2": "これまで見ることができなかったSnoop Doggの世界をお楽しみください。",
    "subtitle3": "友達と遊ぼう",
    "subtitle4": "友だちと一緒に",
    "subtitle5": "あなたがドグ（Dogg）です",
    "paragraph2": "スヌープの屋敷で新しい友達を作ったり、近所の土地を手に入れたりしましょう。",
    "paragraph3": "The Sandboxのメタバースであなたも「スヌープ・ドッグ」になるチャンスがあります。"
  },
  "partners": {
    "title1": "The Sandboxのパートナー",
    "title2": ""
  },
  "footer": {
    "title": "フォローしませんか？",
    "text": "Based on the comic book The Walking Dead by Robert Kirkman, Tony Moore and Charlie Adlard. The Walking Dead is a registered or otherwise protected trademark owned by Robert Kirkman, LLC. Skybound is a registered or otherwise protected trademark owned by Skybound, LLC. Uses with permission.",
    "socialIcons": [
      {
        "name": "discord",
        "url": "https://discordapp.com/invite/vAe4zvY "
      },
      {
        "name": "telegram",
        "url": "https://t.me/sandboxgame"
      },
      {
        "name": "medium",
        "url": "https://medium.com/sandbox-game"
      },
      {
        "name": "twitter",
        "url": "https://twitter.com/TheSandboxGame"
      },
      {
        "name": "instagram",
        "url": "https://www.instagram.com/thesandboxgame/"
      },
      {
        "name": "facebook",
        "url": "https://www.facebook.com/TheSandboxGame/"
      },
      {
        "name": "youtube",
        "url": "https://www.youtube.com/user/TheSandboxGame"
      },
      {
        "name": "twitch",
        "url": "https://www.twitch.tv/thesandboxgame"
      },
      {
        "name": "reddit",
        "url": "https://www.reddit.com/r/TheSandboxGaming/"
      }
    ]
  }
}
