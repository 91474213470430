{
  "meta": {
    "Home": {
      "title": "The Sandbox - Snoop Dogg",
      "description": ""
    },
    "favicon": "/favicon.ico"
  },
  "bannerTop": {
    "title": "Snoop Dogg",
    "subtitle1": "is entering",
    "paragraph1": "Snoop Dogg is building his mansion inside The Sandbox and YOU are invited to the party!",
    "paragraph2": "Play ball, watch movies, hang out by the pool.",
    "paragraph3": "Visit Snoop Dogg's mansion and be as close as never to the king himself."
  },
  "login": {
    "button": "login or create an account",
    "descriptionRegular1": "Sign up and receive an exclusive pair of Snoop Dogg Sunglasses - once the game is available!"
  },
  "loginVIP": {
    "title": "Get a snoop dogg VIP ticket!",
    "button": "login or create an account",
    "descriptionRegular1": "Create your account and get a change to win the limited-edition Snoop Dogg VIP Pass, giving you exclusive VIP access to concerts, events, and other exclusive benefits."
  },
  "bannerMiddle": {
    "title1": "Play, create,",
    "title2": "own and govern",
    "subtitle1": "a virtual world made by players",
    "paragraph1": "Play experiences both single-player and multi-player. Create and animate assets with Voxedit 3D. Build unique game experiences at The Sandbox Metaverse with the Game Maker tool. Buy and Sell assets at the Marketplace to enhance your experience.",
    "paragraph6": "In The Sandbox, the only limit is your imagination.",
    "button1": "Discover Snoop’s NFT collection",
    "title3": "Explore and Discover",
    "subtitle2": "the world of Snoop Dogg like never before. ",
    "subtitle3": "GET YOUR OWN LAND",
    "subtitle4": "",
    "subtitle5": "BECOME THE DOGGFATHER",
    "paragraph2": "Alongside Snoop’s mansion and hang out with friends, old and new!",
    "paragraph3": "You will have the chance to become Snoop Dogg himself, thanks to hand-crafted voxel Avatars that can be used in The Sandbox metaverse!",
    "linkAbout": "Discover The Sandbox"
  },
  "partners": {
    "title1": "Brands & IPs already",
    "title2": "in The Sandbox Metaverse."
  },
  "footer": {
    "title": "Follow us.",
    "text": "Based on the comic book The Walking Dead by Robert Kirkman, Tony Moore and Charlie Adlard. The Walking Dead is a registered or otherwise protected trademark owned by Robert Kirkman, LLC. Skybound is a registered or otherwise protected trademark owned by Skybound, LLC. Uses with permission.",
    "socialIcons": [
      {
        "name": "discord",
        "url": "https://discordapp.com/invite/vAe4zvY "
      },
      {
        "name": "telegram",
        "url": "https://t.me/sandboxgame"
      },
      {
        "name": "medium",
        "url": "https://medium.com/sandbox-game"
      },
      {
        "name": "twitter",
        "url": "https://twitter.com/TheSandboxGame"
      },
      {
        "name": "instagram",
        "url": "https://www.instagram.com/thesandboxgame/"
      },
      {
        "name": "facebook",
        "url": "https://www.facebook.com/TheSandboxGame/"
      },
      {
        "name": "youtube",
        "url": "https://www.youtube.com/user/TheSandboxGame"
      },
      {
        "name": "twitch",
        "url": "https://www.twitch.tv/thesandboxgame"
      },
      {
        "name": "reddit",
        "url": "https://www.reddit.com/r/TheSandboxGaming/"
      }
    ]
  }
}
